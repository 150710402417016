.pied_page
{
    background-color:#202426;
    color: white;
    padding: 25px;
    padding-top: 25px;
    padding-bottom: 50px;
}
.titre_coordonees_pied_page
{
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.section_coordonnes
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.icone_pied_page
{
    font-size: 24px;
}
.conteneur_contenu_article
{
    padding: 25px;
    padding-top: 0px;
}
.lien_article
{
    color: black;
    text-decoration: none !important;
}
.lien_article:hover
{
    color: black;
    text-decoration: none !important;
}
.titre_article
{
    padding: 25px;
}
.image_article
{
    max-height: 200px;
    object-fit: cover;
}
.carte_article_bas_page
{
    background-color: white;
    transition: background-color 0.5s ease-out;
}
.carte_article_bas_page:hover
{
    background-color: #2cb66a;
    transition: background-color 0.5s ease-out;
}