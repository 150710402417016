.titre_section_avantage_accueil
{
    font-family: Poppins;
    font-weight: bold;
    font-style: normal;
    font-size: 32px;
    padding: 25px;
}
.conteneur_avantage_accueil
{
    background-color: black;
    padding: 25px;
    padding-top: 0px;
}
.icone_avantage_accueil
{
    font-size: 72px;
    color: #2cb66a;
    margin: 25px;
}
.titre_sous_section_avantage_accueil
{
    font-family: Poppins;
    font-weight: bold;
    font-style: normal;
    color: white;
    padding: 25px;
    font-size: 24px;
}
.texte_sous_section_avantage_accueil
{
    font-family: Poppins;
    color: black;
    padding: 25px;
    text-align: justify;
    background-color: white;
    hyphens: auto;
}