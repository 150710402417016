.conteneur_titre_page
{
    height:fit-content;
    min-height: 250px;
    padding: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    margin: 0px;
}
.titre_page
{
    color:white;
    font-family: Poppins, serif;
    font-weight: bold;
    background-color: rgba(44, 182, 106, 0.85);
    width: fit-content;
    padding: 25px;
    margin: auto;
    font-size: 32px;
}
.titre_section
{
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
}
@media screen and (screen-width <= 1200) {
    
}