.conteneur_form_inscription
{
    margin: 50px;
}
.bouton_inscription
{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.bouton_inscription:hover
{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.bouton_inscription:focus
{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.premier_titre_section_commande
{
    margin-bottom: 50px;
}
.titre_section_commande
{
    margin-top: 30px;
    margin-bottom: 50px;
}
.bouton_connexion_inscription
{
    color: white;
}
.bouton_connexion_inscription:hover
{
    color: white;
}