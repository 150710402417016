.conteneur_ajout_page
{
    margin-top: 25px;
    margin-bottom: 50px;
}
.form_contenu_page
{
    height: 500px;
    padding-bottom: 50px;
}
.label_ajout_page
{
    margin-bottom: 20px;
    margin-top: 20px;
}
.bouton_ajout_page
{
    margin-top: 25px;
}
.bouton_ajout_page:hover
{
    margin-top: 25px;
}
.bouton_ajout_page:focus
{
    margin-top: 25px;
}