.conteneur_facture
{
    margin-bottom: 50px;
}
.information_facture_tableau
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.titre_facture
{
    margin-top: 25px;
}
.item_panier
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.total_facture
{
    margin-top: 50px;
}