.conteneur_tableau_de_bord
{
    margin: 50px;
}
.conteneur_solde
{
    background-color: rgba(44, 182, 106, 0.85);
    padding: 20px;
    color: white;
    font-size: 28px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.aucune_facture
{
    margin-top: 25px;
}
.factures_tab_bord
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.bouton_action_facture
{
    margin-left: 10px;
    margin-right: 10px;
}
.abonnement_produit
{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}
.bouton_liste_facture
{
    margin-left: 25px;
    margin-right: 25px;
}