.gw_icone_panier
{
    width: 50px;
    font-size: 28px;
    margin-bottom: 5px;
}
.item_panier
{
    margin-top: 25px;
    margin-bottom: 25px;
}