.pied_copyright
{
    font-family: Comfortaa-Regular, serif;
    line-height: 28px;
    font-weight: 400;
    padding: 20px;
    font-size: 14px;
    background-color:#16191b;
    color: white;
    margin: 0px;
    text-align: center;
}
.nav_link_pied_page
{
    font-family: Comfortaa-Regular, serif;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: white;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.2s ease-out;
}
.nav_link_pied_page:hover
{
    font-family: Comfortaa-Regular, serif;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: rgba(44, 182, 106, 0.85);
    font-size: 14px;
    text-decoration: none;
    transition: color 0.2s ease-out;
}