.conteneur_resultat_recherche
{
    margin-top: 50px;
}
.resultat_recherche
{
    margin: 10px;
    min-height: 225px;
}
.bouton_voir_page
{
    margin: 15px;
}
.bouton_voir_page:hover
{
    margin: 15px;
    background-color: #3f584a;
}
.alerte_resultat
{
    margin-bottom: 50px;
}