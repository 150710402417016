.form_contact
{
    padding: 50px;
}
.bouton_form_contact
{
    width: 100% !important;
}
.zone_lien_contact
{
    margin-top: 20px;
    margin-bottom: 20px;
}
.lien_contact
{
    color: black;
    transition: color 0.2s ease-out;
}
.lien_contact:hover
{
    color: rgba(44, 182, 106, 1);
    transition: color 0.2s ease-out;
}