.conteneur_service
{
    margin: 50px;
    align-items: center;
}
.texte_service
{
    text-align: justify;
    hyphens: auto;
    background-color: #202426;
    padding: 20px;
    color: white;
}
.texte_montant_service
{
    font-size: 2rem;
}
.section_achat_service
{
    margin: auto;
}
.texte_duree_montant
{
    font-size: 24px;
    vertical-align: middle;
    padding-left: 10px;
}
.bouton_action_option
{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
}
.alerte_ajout_panier
{
    margin-top: 25px;
    margin-bottom: 25px;
}