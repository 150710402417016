.titre_projet_accueil
{
    font-family: Poppins;
    font-weight: bold;
    font-style: normal;
    margin-top: 25px;
    font-size: 32px;
    padding: 25px;
}
.caption_projet
{
    background-color: black !important;
    position: relative;
    left: 50%;
    height: fit-content;
    transform: translateX(-50%);
    padding: 50px;
}
.image_projet
{
    width: 100%;
    height: 20%;
    object-fit: cover;
    object-position: top;
}
.description_projet
{
    text-align: justify;
    hyphens: auto;
}