.conteneur_liste_article
{
    margin: 50px;
}
.conteneur_contenu_article
{
    padding: 25px;
    padding-top: 0px;
}
.lien_article
{
    color: black;
    text-decoration: none !important;
}
.lien_article:hover
{
    color: black;
    text-decoration: none !important;
}
.titre_article
{
    padding: 25px;
}