.conteneur_video_youtube_accueil
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.titre_video_youtube_accueil
{
    font-family: Poppins;
    font-weight: bold;
    font-style: normal;
    font-size: 32px;
    padding: 25px;
}
.video_youtube_accueil
{
    width: 100%;
    height: 500px;
}
