/* GreenWood Multimedia © 2021 - Tous droits réservés. */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/** POLICES */
@font-face {
    font-family: Comfortaa-Bold;
    src: url("../public/polices/Comfortaa-Bold.ttf");
}
@font-face {
    font-family: Comfortaa-Light;
    src: url("../public/polices/Comfortaa-Light.ttf");
}
@font-face {
    font-family: Comfortaa-Regular;
    src: url("../public/polices/Comfortaa-Regular.ttf");
}

body
{
    overflow-x: hidden;
}
#root
{
    overflow-x: hidden;
}
.btn-primary
{
    border-radius: 20px;
    background-color: #2cb66a;
    font-family: Poppins;
    font-weight:lighter;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top:10px;
    border: 0;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus
{
    border-radius: 20px;
    color: whitesmoke;
    background-color: #3f584a;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top:10px;
    border: 0;
}
.btn-primary:disabled
{
    border-radius: 20px;
    background-color: grey;
    padding: 10px;
    margin-top:10px;
}
.alert
{
    background-color:rgba(44, 182, 106, 1);
    color:white;
    border: 0px;
}
.alert > .bi
{
    margin-right:10px;
}
#bouton_panier_service
{
    margin-left:20px;
}
.figure-caption
{
    margin-top: 5px;
}
.table
{
    margin-bottom: 0px;
}
td
{
    overflow-wrap: anywhere;
}
.form-control
{
    margin-top:10px;
    margin-bottom:10px;
}
.form-check-input
{
    margin-top:13px;
    margin-bottom:10px;
    margin-right: 10px;
}
.form-check-label
{
    margin-top:10px;
    margin-bottom:10px;
}
.form-group > label
{
    margin-top:10px;
    margin-bottom:10px;
}
.carousel-control-prev-icon
{
    background-color:#2cb66a;
}
.carousel-control-next-icon
{
    background-color:#2cb66a;
}
@media print {
    .no-print {display:none;}
 }