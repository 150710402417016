.conteneur_principes
{
    margin: 50px;
}
.carte_principe
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.texte_image_cb
{
    margin: 10px;
    margin-left: 50px;
    margin-right: 50px;
}
.titre_principle_global
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.titre_principe
{
    margin: 25px;
    margin-top: 0px;
}
.texte_principe
{
    text-align: justify;
    padding: 10px;
}