.alerte_erreur_contenu
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.conteneur_contenu_page
{
    padding: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.conteneur_liste_article
{
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: justify !important;
}
.image_portfolio
{
    width: 80%;
    margin: 10%;
    margin-top: 25px;
    object-fit: contain;
    object-position: top;
    margin-bottom: 50px;
}