.conteneur_effectuer_commande
{
    margin: 50px;
}
.element_panier_commande
{
    margin-top: 25px;
    margin-bottom: 25px;
}
.total_panier
{
    margin-bottom: 25px;
}