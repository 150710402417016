.gw_conteneur_navigation
{
    display: flex;
    align-items: center;
    padding-left:25px;
    padding-right:25px;
    color: white;
    justify-content: center;
    vertical-align: middle;
}
.gw_nav_dropdown_admin > a
{
    color: black !important;
    font-family: Comfortaa-Regular;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: black !important;
    font-size: 14px;
    margin-top: 9px;
    padding: 5px;
    transition: color 0.2s ease-out;
}
.gw_nav_dropdown_admin > a:hover
{
    color: #2cb66a !important;
    transition: color 0.2s ease-out;
}
.gw_nav_dropdown > a
{
    color: black !important;
    font-family: Comfortaa-Regular;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: black !important;
    font-size: 14px;
    margin-top: 5px;
    padding: 5px;
    transition: color 0.2s ease-out;
}
.gw_nav_dropdown > a:hover
{
    color: #2cb66a !important;
    transition: color 0.2s ease-out;
}
.gw_nav_link
{
    color: black !important;
    font-family: Comfortaa-Regular;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: black !important;
    font-size: 14px;
    margin-top: 5px;
    padding: 5px;
    transition: color 0.2s ease-out;
}
.gw_nav_link:hover
{
    color: #2cb66a !important;
    transition: color 0.2s ease-out;
}
.gw_logo
{
    margin: 0px;
    margin-top:20px;
    margin-bottom:20px;
    width: 200px;
    height: 25px;
}
.gw_icone
{
    width: 30px;
    font-size: 32px;
    padding: 4px;
}