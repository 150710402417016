/*  BANNIERE */
.banniere
{
    height: fit-content;
    min-height: 575px;
    background-image: url("https://api.greenwoodmultimedia.com/images/banniere/foret-de-bouleau.jpg");
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: cover;
    object-fit: cover;
    margin:0px;
}
.boite_slogan
{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    align-self: center;
    background-color: rgba(0,0,0, 0.85);
    height: fit-content;
    width: fit-content;
    padding: 30px;
}
.boite_slogan_sous_titre
{
    text-align: left;
    font-family: Poppins, serif;
    color: white;
    font-style: normal;
    font-size: 18px;
}
.boite_slogan_titre
{
    text-align: left;
    font-family: Poppins, serif;
    color: white;
    font-style: normal;
    font-weight: bold;
    line-height: 52px;
    font-size: 52px;
}
.bouton_action_banniere
{
    align-items: start;
}