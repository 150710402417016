.conteneur_banniere_avis
{
    height: fit-content;
    min-height: 300px;
    background-image: url("https://api.greenwoodmultimedia.com/images/banniere/foret-de-bouleau.jpg");
    margin-top: 50px;
}
.conteneur_avis
{
    background-color: #202426;
    color: white;
    padding: 50px;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    width: fit-content;
    height: fit-content;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.titre_conteneur_avis
{
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 42px;
}
.bouton_call_to_action
{
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 24px;
}
.bouton_call_to_action:hover, .bouton_call_to_action:focus
{
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 24px;
}